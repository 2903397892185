<template>
  <v-list-item v-if="itemList" class="primary--text" @click="onPrint">
    <v-list-item-icon>
      <icon-print outlined size="1.5em" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="$t('invoice.print')" />
    </v-list-item-content>
  </v-list-item>
  <v-btn v-else color="primary" depressed @click="onPrint">
    <icon-print class="mr-2" outlined size="1.5em" />
    {{ $t("invoice.print") }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceData } from "@planetadeleste/vue-mc-gw";
import IconPrint from "@/components/icons/IconPrint.vue";
import IconLoading from "@/components/icons/IconLoading.vue";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { IconPrint, IconLoading },
})
export default class InvoicePdfPrint extends Vue {
  @Prop(Object) readonly item!: InvoiceData;
  @Prop(Boolean) readonly itemList!: string;

  async onPrint() {
    if (!this.item) {
      return;
    }

    EventBus.emit("invoice.print", this.item);
  }
}
</script>
